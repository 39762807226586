import * as React from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {FaFacebookSquare, FaLinkedin, FaEnvelope, FaPhoneSquareAlt, FaRegCopyright} from 'react-icons/fa';

export default class PageFooter extends React.Component<any, any> {
    render() {
        return (
            <div className="pad-v text-light bg-dark">
                <Container>
                    <Row className="justify-content-between">
                        <Col xs={4}>
                            <div>Copyright</div>
                            <div>
                                <FaRegCopyright/> 2020
                            </div>
                            <div>Josh Nicks</div>
                        </Col>
                        <Col className="text-right">
                            {lnk(`mailto:${email}`, email, emailIcon)}
                            <div>+1.208.908.8529 {phoneIcon}</div>
                            {lnk(facebookUrl, 'Facebook Profile', facebookIcon)}
                            {lnk(linkedInUrl, 'LinkedIn Profile', linkedInIcon)}
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

const phoneIcon = <FaPhoneSquareAlt/>
const linkedInIcon = <FaLinkedin/>;
const linkedInUrl = 'https://www.linkedin.com/in/joshua-nicks-92505a1/';
const facebookIcon = <FaFacebookSquare/>;
const facebookUrl = 'https://www.facebook.com/joshua.nicks.1';
const emailIcon = <FaEnvelope/>;
const email = 'jnicks42@outlook.com'

const lnk = (url: string, content: string, icon: JSX.Element) => (
    <div>
        <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="uncolored"
        >
            {content} {icon}
        </a>
    </div>
)
