import * as React from 'react';
import PageHeader from './PageHeader';
import PageFooter from './PageFooter';

export default class PageLayout extends React.Component<any, any> {
    render() {
        return (
            <div>
                <PageHeader />
                {this.props.children}
                <PageFooter />
            </div>
        );
    }
}
