import * as React from 'react';
import { Container } from 'react-bootstrap';
import * as GENERAL from './generalContent';
import * as OUTDOORS from './outdoorsContent';
import * as MUSIC from './musicContent';
import * as FOOD from './foodContent';
import * as GEEK from './geekContent';

export default class Home extends React.Component<any, any> {
    render() {
        return (
            <Container className="pad-v personal">
                {GENERAL.aboutMe}
                {OUTDOORS.outdoors}
                {FOOD.foodie}
                {MUSIC.music}
                {GEEK.geeking}
            </Container>
        )
    }
}
