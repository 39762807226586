import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';

import PageLayout from './PageLayout';
import Resume from '../static-pages/resume/Resume';
import Home from '../static-pages/home/Home';

export const routes = (
    <PageLayout>
        <Route path="/home" component={Home} />
        <Route path="/resume" component={Resume} />
        <Redirect from="/" to="/home" />
    </PageLayout>
)
