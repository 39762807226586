import * as React from 'react';
import {CompetenceEntry} from './resumeContent';
import {DiHtml5, DiJavascript1, DiDotnet, DiJava, DiRuby, DiPython, DiMysql, DiPostgresql, DiMongodb, DiApple, DiWindows, DiLinux, DiScrum} from 'react-icons/di';
import {GoPrimitiveDot, GoQuestion} from 'react-icons/go';
import {GrUserManager} from 'react-icons/gr';

interface Props {
    data: Array<CompetenceEntry>
}

export default class CompetenceList extends React.Component<Props, any> {
    render() {
        return (
            <div>
                {this.props.data.map((d, i) => section(d, i))}
            </div>
        );
    }
}

const section = (data: CompetenceEntry, index: number) => (
    <div key={`competence-section-${index}`} className="pad-b-sm">
        <h4 className="text-uppercase">{data.name}</h4>
        {data.values.map((v, i) => item(v, i))}
    </div>
);

const item = (data: string, index: number) => {
    if (data.includes('<-hd')) {
        let stripped = data.replace('<-hd', '');
        return <div key={`item-${index}`} className="competence-hdr">{stripped}</div>
    }
    return (
        <div key={`item-${index}`} className="font-gothic">{getIcon(data)} {data}</div>
    );
};

const getIcon = (name: string): any => {
    switch (name.toLowerCase()) {
        case 'javascript':
            return <DiJavascript1/>;
        case 'html':
            return <DiHtml5/>;
        case 'c#':
            return <DiDotnet/>;
        case 'java':
            return <DiJava/>;
        case 'ruby':
            return <DiRuby/>;
        case 'python':
            return <DiPython/>;
        case 'mysql':
            return <DiMysql/>;
        case 'postgresql':
            return <DiPostgresql/>;
        case 'cosmosdb':
        case 'mongodb':
            return <DiMongodb/>;
        case 'ios':
        case 'mac os':
            return <DiApple/>;
        case 'windows':
            return <DiWindows/>;
        case 'linux':
            return <DiLinux/>;
        case 'scrum / agile':
            return <DiScrum/>;
        case 'strategic thinking':
            return <GoQuestion/>;
        case 'management':
            return <GrUserManager/>;
        default:
            return <GoPrimitiveDot/>;
    }
};
