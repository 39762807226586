import * as React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import * as STATIC from './resumeContent';
import ExperienceList from "./ExperienceList";
import CompetenceList from "./CompetenceList";
import experience from './experience.json';
import competence from './competence.json';
import '../../css/resume.scss';

export default class Resume extends React.Component<any, any> {
    render() {
        return (
            <Container>
                {STATIC.summary}
                <Row>
                    <Col sm={5} lg={4}>
                        <h2>Key Competence</h2>
                        <CompetenceList data={competence}/>
                    </Col>
                    <Col>
                        <h2>Professional Experience</h2>
                        <ExperienceList jobs={experience} skip={0} take={2}/>
                    </Col>
                </Row>
                <ExperienceList jobs={experience} skip={2} take={null}/>
                {STATIC.education}
            </Container>
        )
    }
}
