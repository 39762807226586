import * as React from 'react';
import {Row, Col, Image} from 'react-bootstrap';
import {JobEntry} from "./resumeContent";
import {AiOutlineCheckCircle} from 'react-icons/ai';

import witlyLogo from '../../img/resume/witly.png';
import simplotLogo from '../../img/resume/simplot.jpg';
import metageekLogo from '../../img/resume/metageeklogo.png';
import cradlepointLogo from '../../img/resume/cradlepointlogo.jpg';

interface Props {
    data: JobEntry;
}

export default class ExperienceItem extends React.Component<Props, any> {
    render() {
        let {data} = this.props;
        return (
            <div className="font-gothic pad-b-sm">
                <Row className="align-items-end">
                    <Col md={2}>{img(data)}</Col>
                    <Col>
                        <h3>{data.title}, {data.company}</h3>
                        <h5>{data.loc} <span className="text-secondary">{data.beg} - {data.end}</span></h5>
                    </Col>
                </Row>
                <Row className="pad-t-xs">
                    <Col>
                        <p>{data.description}</p>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ul>
                            {data.accomplishments.map((item, i) => accomplishment(item, i))}
                        </ul>
                    </Col>
                </Row>
            </div>
        );
    }
}

const img = (data: JobEntry): JSX.Element => {
    let src = '';
    switch (data.image) {
        case 'witly':
            src = witlyLogo;
            break;
        case 'metageek':
            src = metageekLogo;
            break;
        case 'simplot':
            src = simplotLogo;
            break;
        case 'cradlepoint':
            src = cradlepointLogo;
            break;
    }
    return (
        <a href={data.link} target="_blank" rel="noopener noreferrer">
            <Image src={src} rounded={true} fluid={true}/>
        </a>
    )
}

const accomplishment = (item: string, i: number) => (
    <div key={`acc-${i}`}>
        <Row>
            <Col xs={1}>
                <AiOutlineCheckCircle />
            </Col>
            <Col>
                {item}
            </Col>
        </Row>
    </div>
)
