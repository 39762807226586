import {Col, Image, Row} from "react-bootstrap";
import powerWagon from "../../img/personal/outdoors/pwr-wagon.jpg";
import mountains from "../../img/pano-mtn.jpg";
import offRoad from "../../img/personal/outdoors/offroad.jpg";
import runner from "../../img/personal/outdoors/4runner.jpg";
import ember1 from '../../img/personal/outdoors/ember.jpg';
import ember2 from '../../img/personal/outdoors/ember2.jpg';
import * as React from "react";

export const outdoors = (
    <Row className="pad-v">
        <Col xs={12} className="pad-v">
            <h2>Idaho Outdoors</h2>
            <div className="desc">
                Idaho is wild. Literally. There are 4,792,969 acres of gorgeous wilderness in Idaho and
                I’m on a mission to get to them all.
            </div>
        </Col>
        <Col xs={12} className="mar-b">
            <Row>
                <Col md={4}>
                    <Image src={ember1} rounded={true} fluid={true}/>
                </Col>
                <Col md={4}>
                    <h4 className="cs-orange">Ember</h4>
                    <p>
                        This is my faithful companion, Ember. She's an incredible German Shepherd Dog from Thomas
                        Schoder in Melba, ID. She is constantly by my side in both work and play. She came into my life
                        in 2016 and has been a real blessing for me. We've had many adventures together in the short
                        time we've had and we both look forward to many more!
                    </p>
                    <p>
                        Ember loves to spend time with my family and I, especially outdoors. She loves to play in the
                        streams, conquer the trails, and play on my sister's farm. If she drops a tug or ball at your
                        feet, be ready to play because she's full of energy and always ready to go.
                    </p>
                </Col>
                <Col md={4}>
                    <Image src={ember2} rounded={true} fluid={true}/>
                </Col>
            </Row>
        </Col>
        <Col md={6} className="mar-b">
            <h4 className="cs-orange">Hiking and Camping</h4>
            <p>
                Whether you love backpacking, glamping, hot springs, lakes, or even dried up old volcanoes, Idaho has it
                all. We might hit the trails on our way to the skeleton of a B-23 Dragon Bomber nestled near a lake on a
                scenic mountain top. On a different day, it’s all about a soak in hot springs the tourists don’t know
                about. Shhh.
            </p>
            <Image src={mountains} rounded={true} fluid={true}/>
        </Col>
        <Col md={6}>
            <h4 className="cs-orange">Offroading</h4>
            <p>
                Sometimes I just want to go for a drive. Sometimes I want that drive to be on a rough road that pushes
                my truck to the edge. I can't explain why, but there is just something about it. At times like these, I
                hop in my 4Runner and scratch that itch on back roads and in mountain passes. I love my 4Runner. She's
                been truly amazing. One of these days I'd like to change it up for a Ram Power Wagon though. I miss the
                utility of driving an actual truck!
            </p>
            <Row>
                <Col md={6} className="mar-b">
                    <Image src={runner} rounded={true} fluid={true}/>
                </Col>
                <Col md={6} className="mar-b">
                    <Image src={powerWagon} rounded={true} fluid={true}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Image src={offRoad} rounded={true} fluid={true}/>
                </Col>
            </Row>
        </Col>
    </Row>
);

