import {Col, Image, Row} from 'react-bootstrap';
import headShot from '../../img/headshot.jpg';
import * as React from 'react';

export const aboutMe = (
    <Row>
        <Col sm={3}>
            <Image src={headShot} rounded={true} fluid={true}/>
        </Col>
        <Col>
            <h2>Hello World!</h2>
            <h4 className="cs-lt-grey text-justify">
                If you’re looking for an experienced software engineer, manager, and entrepreneur
                living in Idaho with his amazing wife and awesome family, you found me. I am a
                geek, an outdoorsman, and a lover of culture. I love to learn and experience life to the
                fullest. Here’s a bit of my story.
            </h4>
        </Col>
    </Row>
);
