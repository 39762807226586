import {Col, Image, Row} from "react-bootstrap";
import martin from "../../img/martin.jpg";
import flute from "../../img/flute.jpg";
import * as React from "react";

export const music = (
    <Row>
        <Col>
            <h2>Music</h2>
            <div className="desc">
                I listen music of all kinds. A few of the genres you might find in my playlist are classical, classic
                rock, heavy metal, country, pop, folk, and reggae. While listening to music is great, I also love to
                make music of my own. As a kid, I went through a few instruments. I started with a recorder, then played
                a viola, then a french horn, and finally a trumpet. None of them really stuck with me. As an adult, I
                took up the Native American Flue and concert flute as well as the guitar. These have stuck with me and
                provide a continual source of joy and connection I can't get any other way.
            </div>
            <h4>Guitar</h4>
            <p>
                Guitar is my go-to instrument of choice these days. I haven't gone 'insane' with them yet, but I do have
                a couple of acoustics and an electric. I will often take a break to noodle around with some scales or
                practice chords. Sometimes it helps me think just to have an instrument in my lap. I'm no expert. I
                wouldn't even say that I play well, but the personal peace and sense of accomplishment I get from it
                makes it all totally worthwhile.
            </p>
            <h4>Native American Flute</h4>
            <p>
                Native American Flutes have been the most immediately rewarding. These things are great.
                It takes almost no skill to make it sound good with the simplest of melodies. Yet,
                somehow masters continue to take these flutes to new heights. Take one to the woods and
                it’s more soothing and meditative than any new age method promised in a 4am infomercial.
                Takes me right to the source. Try it.
            </p>
        </Col>
        <Col sm={4} className="text-right">
            <Image src={martin} rounded={true} fluid={true}/>
            <Image src={flute} rounded={true} fluid={true}/>
        </Col>
    </Row>
);
