import {Col, Image, Row} from 'react-bootstrap';
import glenfiddich from '../../img/personal/foodie/glenfiddich-18.png';
import highlandPark from '../../img/personal/foodie/highland-park-18.jpg';
import macallan from '../../img/personal/foodie/macallan-18.jpg';
import spaetzle from '../../img/personal/foodie/german.jpg';
import scapa from '../../img/personal/foodie/scapa-skiren.jpg';
import mead from '../../img/personal/foodie/mead.jpg';
import ribs from '../../img/personal/foodie/ribs.jpg';
import pie from '../../img/personal/foodie/pie.jpg';
import * as React from 'react';

const homeBrew = (
    <Row className="mar-b">
        <Col sm={4}>
            <Image rounded={true} src={mead} fluid={true}/>
            <div className="caption">Cinnamon Mead</div>
        </Col>
        <Col>
            <h4 className="cs-orange">Home Brewing</h4>
            <p>
                Hopped, not sweet. I love making hard cider. The family favorite is a dry hopped cider that I usually
                keep on tap. When I’m not trying to keep the neighborhood in cider, I’m trying out new mead recipes.
                Something about ties to ancient ancestors and the purity of fresh raw honey just works. Definitely, the
                best part of brewing is sharing with the people in your life.
            </p>
        </Col>
    </Row>
);

const barbeque = (
    <Row className="mar-b">
        <Col>
            <h4 className="cs-orange">Barbeque</h4>
            <p>
                Who doesn't love barbeque? Years ago I invested in a Traeger grill and it changed my life. I started
                with brisket and steaks, then found my way to tri-tip, ribs, beer can chicken, jerky, pulled pork and
                all other standard barbeque fare. There's just something about 3-2-1 baby back ribs braised in homemade
                hard cider and graced with a skiff of homemade barbeque sauce that just satisifes.
            </p>
        </Col>
        <Col sm={4}>
            <Image rounded={true} src={ribs} fluid={true}/>
            <div className="caption">Baby Back Ribs</div>
        </Col>
    </Row>
);

const baking = (
    <Row className="mar-b">
        <Col sm={4}>
            <Image rounded={true} src={pie} fluid={true}/>
            <div className="caption">Apple Pie</div>
        </Col>
        <Col>
            <h4 className="cs-orange">Baking</h4>
            <p>
                A few years ago, I was informed that we'd be having a small private Thanksgiving dinner. Oddly, my first
                question was, "Who's going to bring pie then?" My lovely wife told me that I had 5 weeks to learn and
                should probably get to it. After a little trial and error, I had recipes I liked for both pecan and
                pumpkin pie. I also had a recipe and method that made me happy for crust from scratch. As it turns out,
                I really like baking. I'm still making pies, but I also like to play with breads, cookies and other
                things.
            </p>
        </Col>
    </Row>
);

const spirits = (
    <Row className="mar-b">
        <Col sm={12}>
            <h4 className="cs-orange">Spirits</h4>
            <p>
                I love to drink but not to drunk. Fine spirits make for a wonderful experience, especially when shared
                with those you love. I wouldn't say that I'm religious about my drink preferences, but I do know what I
                like. I like Whiskey, Gin, Tequila, Rum, Vodka, Beer and Wine. I truly enjoy a nice dram of Scotch
                Whisky as well. For the past several years, I've been trying lots of different kinds when I get the
                opportunity. Below are some of my favorites.
            </p>
        </Col>
        <Col xs={6} md={3} className="caption mar-b">
            <Image rounded={true} src={macallan} fluid={true}/>
            Macallan
        </Col>
        <Col xs={6} md={3} className="caption mar-b">
            <Image rounded={true} src={highlandPark} fluid={true}/>
            Highland Park
        </Col>
        <Col xs={6} md={3} className="caption mar-b">
            <Image rounded={true} src={scapa} fluid={true}/>
            Scapa
        </Col>
        <Col xs={6} md={3} className="caption mar-b">
            <Image rounded={true} src={glenfiddich} fluid={true}/>
            Glenfiddich
        </Col>
    </Row>
);

const miscFood = (
    <Row className="mar-b">
        <Col>
            <h4 className="cs-orange">Miscellaneous Food</h4>
            <p>
                I've always enjoyed cooking, ever since I was a kid. My first few jobs were in the food industry, albeit
                'fast food'. Some time ago, I struggled with the decision to go to culinary school or go all-in on my
                career in Software Engineering. I did ultimately choose software but I still love to cook. I'm always
                trying new things and different cuisines. One day I could be making Japanese Yakisoba, and the next
                French Onion Soup. I love the way a meal from scratch tastes and I love the smiles on my family's faces
                when they enjoy my creations.
            </p>
        </Col>
        <Col sm={4} className="text-center">
            <Image rounded={true} src={spaetzle} fluid={true}/>
            <div className="caption">Geschnetzeles</div>
        </Col>
    </Row>
);

export const foodie = (
    <Row>
        <Col xs={12}>
            <h2>Food & Drink</h2>
        </Col>
        <Col xs={12}>
            <div className="desc"> You could say I'm a foodie. I'm one of those weirdos who takes pictures of his food,
                but usually only if I make it. I love to create things for others to eat and drink. Here's a few of
                things I've played around with...
            </div>
        </Col>
        <Row className="pad-t">
            <Col xs={12} md={6}>{homeBrew}</Col>
            <Col xs={12} md={6}>{barbeque}</Col>
        </Row>
        <Row>
            <Col xs={12} md={6}>{baking}</Col>
            <Col xs={12} md={6}>{miscFood}</Col>
        </Row>
        <Row>
            <Col xs={12}>{spirits}</Col>
        </Row>
    </Row>
);