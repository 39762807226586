import * as React from 'react';
import ExperienceItem from "./ExperienceItem";
import {JobEntry} from "./resumeContent";

interface Props {
    jobs: Array<JobEntry>;
    skip: number;
    take: number | null;
}

export default class ExperienceList extends React.Component<Props, any> {
    render() {
        let end = this.props.take ? this.props.skip + this.props.take : undefined;
        let partial = this.props.jobs.slice(this.props.skip, end);
        return (
            <div>
                {partial.map((p, i) => <ExperienceItem key={`job-${i}`} data={p} />)}
            </div>
        )
    }
}
