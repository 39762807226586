import * as React from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {FaFilePdf, FaEnvelope, FaPhone, FaReact} from 'react-icons/fa';

export interface JobEntry {
    title: string;
    company: string;
    image: string;
    link: string;
    loc?: string;
    beg: string;
    end: string;
    description: string;
    accomplishments: Array<string>;
}

export interface CompetenceEntry {
    name: string;
    values: Array<string>;
}

export const summary = (
    <div className="pad-v">
        <Row>
            <Col>
                <h1>
                    <span className="cs-lt-grey">JOSH</span><span className="cs-orange">NICKS</span>
                </h1>
                <h3 className="cs-brown font-gothic">Software Engineer | Manager</h3>
                <a href="https://www.dropbox.com/s/8fziirxanl32x82/Josh%20Nicks%20Resume.pdf?dl=0"
                   target="_blank" rel="noopener noreferrer">
                    <Button variant="secondary">
                        DOWNLOAD PDF <FaFilePdf/>
                    </Button>
                </a>
            </Col>
            <Col sm={4} className="text-right">
                <div>+1.208.908.8529 <FaPhone/></div>
                <div><a href="mailto:jnicks42@outlook.com" className="uncolored">jnicks42@outlook.com <FaEnvelope/></a>
                </div>
                <div><a href="https://joshnicks.com" className="uncolored">joshnicks.com <FaReact/></a></div>
            </Col>
        </Row>
        <Row className="pad-t">
            <Col className="text-center">
                <h2 className="cs-lt-grey">
                    TALENTED AND DEDICATED SOFTWARE ENGINEER WITH PROVEN ABILITY TO LEAD AND MOTIVATE HIGH PERFORMANCE
                    TEAMS
                </h2>
            </Col>
        </Row>
    </div>
);

export const education = (
    <div className="pad-v">
        <h2 className="cs-orange text-uppercase">Education</h2>
        <div className="font-gothic">
            <div>Course work in Mathematics and Computer Science</div>
            <div><span className="cs-brown">Boise State University</span> <span className="cs-lt-grey">Boise, ID</span></div>
        </div>
    </div>
)
