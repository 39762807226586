import {Col, Image, Row} from "react-bootstrap";
import code from "../../img/code.jpg";
import walkers from "../../img/walkers.jpg";
import * as React from "react";

export const geeking = (
    <div>
        <Row className="pad-t">
            <Col>
                <h2>Geeking Out</h2>
                <div className="desc">
                    I'm a geek, plain and simple. Geeking out feels like play but looks like work to the rest of the
                    world. As a software developer and Wi-Fi Engineer, I enjoy speed dating new tech as it gets to
                    the market - the only kind of speed dating that I do! As an entrepreneur and manager, I’m into
                    things like EQI, strategic thinking, and marketing strategies. My inner kid, he’s all about
                    superheroes, Sci-Fi, Fantasy, table-top gaming, and video games. Really, who isn’t?
                </div>
            </Col>
        </Row>
        <Row className="pad-v">
            <Col sm={4}>
                <Image rounded={true} src={code} fluid={true} className="mar-b"/>
            </Col>
            <Col>
                <h4 className="cs-orange">Software Development & Testing</h4>
                <p>
                    I get it right because I’m involved. Message me if you’re also preoccupied by things
                    like test driven development, continuous integration, version control, and peer review.
                    How about development frameworks, languages, and design patterns emerging in the
                    industry? I’m curious how you’re doing it better than the rest. Seriously, my
                    dedication to best practices borders on obsession. I even built this website in ReactJS just so I
                    could learn it.
                </p>
            </Col>
        </Row>
        <Row className="pad-t">
            <Col>
                <h4 className="cs-orange">Entertainment</h4>
                <p>
                    I’m easily entertained. Boredom simply doesn’t exist in my life. If all else fails,
                    Sci-Fi and video games never let me down. My go to is Star Wars Episode V: The Empire
                    Strikes Back because it is the best movie ever made. My sincerest wish for humanity is
                    that everyone could live long and prosper, find their patronus, drop a gold ring into a
                    volcano, or just be a hero. It’s fine if you need a book, video, or occasional video
                    game to get you there.
                </p>
            </Col>
            <Col sm={6}>
                <Image rounded={true} src={walkers} fluid={true}/>
            </Col>
        </Row>
    </div>
);