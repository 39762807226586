import * as React from 'react';
import { Navbar, Nav, Image } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import headshot from '../img/logo.png';

export default class PageHeader extends React.Component<any, any> {
    render() {
        return (
            <Navbar variant="dark" bg="dark" className="align-items-end" expand="sm" collapseOnSelect={true}>
                <Navbar.Brand>
                    <Image src={headshot} alt="Josh Nicks" className="w-auto" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                <Nav>
                    {item('HOME', '/home')}
                    {item('RESUME', '/resume')}
                </Nav>
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

const item = (text: string, dest: string) => (
    <LinkContainer to={dest}>
        <Nav.Link>{text}</Nav.Link>
    </LinkContainer>
);
